












































// Core
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'

// Mixins
import Documents from '@/mixins/Documents'

// Store
import { worksNamespace } from '@store/works'

// Types
import { WorksActionTypes } from '@store/works/Types'

// Interfaces
import { Role } from '@store/common/Interface'
import { IExecutiveDocumentation, IFact, IWorksState } from '@store/works/Interface'

@Component
export default class FormFormationId extends Mixins(Documents) {
  @Prop({ default: null }) private workId!: number

  @worksNamespace.State((state: IWorksState) => state.facts)
  private facts!: IFact[]

  @worksNamespace.Action(WorksActionTypes.A_FETCH_FACT)
  private fetchFacts!: (workId: number) => Promise<IFact[]>

  @worksNamespace.Action(WorksActionTypes.A_ADD_MULTI_FACT)
  private addFacts!: (params) => Promise<void>

  private checkList: any = []

  get disabledButton() {
    return this.checkList.length
  }

  private created() {
    this.fetchFacts(this.workId)
  }

  private downloadExecutive(docs: IExecutiveDocumentation[]) {
    docs.forEach(element => {
      this.downloadDocument(element.downloadLink)
    })
  }

  private send() {
    this.addFacts({ list: this.checkList, workId: this.workId })
      .then(() => {
        this.$emit('close-dialog')
        this.$notify({
          title: 'Выполнено',
          message: 'Действие успешно выполнено',
          type: 'success',
        })
      })
      .catch(error => {
        this.$notify.error({
          title: 'Ошибка',
          message: error.response.data.message,
        })
      })
  }
}
